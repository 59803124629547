import { Heading, IconCheck, useLargeFromMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import styles from './PaymentProtection.module.css';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const PAYMENT_PROTECTION_HTML_ID = 'Explore_more_product_services';

export const PaymentProtection = (): ReactElement | null => {
  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  const isLargeFromViewport = useLargeFromMediaQuery();

  if (!isHomePageComponentChangesEnabled) {
    return null;
  }

  const protections = [
    'Pay securely through the Mad Paws website and app.',
    'Your payment is only released when the booking is completed.',
    'Skip the anxiety of paying for pet care up-front with cash.',
  ];

  return (
    <section className={styles.root} id={PAYMENT_PROTECTION_HTML_ID}>
      <div className={styles.contentWrapper}>
        <div className={styles.headingWrapper}>
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            size={isLargeFromViewport ? 'large1x' : 'small1x'}
          >
            Payment protection
          </Heading>
        </div>
        <div className={styles.trustBuilders}>
          {protections?.map((protection) => (
            <div className={styles.trustBuilderWrapper} key={protection}>
              <IconCheck />
              <div className={styles.trustBuilder}>{protection}</div>
            </div>
          ))}
        </div>
      </div>
      <ImageWithFallback
        alt="Picture of a trusted pet sitter"
        fallback={`${publicRuntimeConfig.staticPath}/images/homepage/PaymentProtection.png`}
        loading="lazy"
        webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/PaymentProtection.webp`}
      />
    </section>
  );
};
