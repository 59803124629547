import { useMediumFromMediaQuery } from '@madpaws/design-system';
import dynamic from 'next/dynamic';

import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import { HowDoesMadPawsWork as HowDoesMadPawsWorkNew } from './components/new/HowDoesMadPawsWork';

import type { ReactElement } from 'react';

const HowDoesMadPawsWorkDesktop = dynamic(() =>
  import('./components/desktop/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);
const HowDoesMadPawsWorkMobile = dynamic(() =>
  import('./components/mobile/HowDoesMadPawsWork').then((module) => module.HowDoesMadPawsWork)
);

export const HowDoesMadPawsWork = (): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();

  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  if (isHomePageComponentChangesEnabled) {
    return <HowDoesMadPawsWorkNew />;
  }

  return isMediumFromViewport ? <HowDoesMadPawsWorkDesktop /> : <HowDoesMadPawsWorkMobile />;
};
