import { Heading, IconShieldCheckOutlined, useLargeFromMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import styles from './AccidentCover.module.css';
import { MP_COVER_LINK } from '../../SitterProfile/Policies/constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const ACCIDENT_COVER_HTML_ID = 'Explore_more_product_services';

export const AccidentCover = (): ReactElement | null => {
  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  const isLargeFromViewport = useLargeFromMediaQuery();

  if (!isHomePageComponentChangesEnabled) {
    return null;
  }

  const COVERS = [
    { title: '$5,000', description: 'Eligible vet bills' },
    { title: '$10m', description: 'Public liability cover' },
    { title: 'Customer support', description: 'During booking' },
  ];

  return (
    <section className={styles.root} id={ACCIDENT_COVER_HTML_ID}>
      {!isLargeFromViewport && (
        <ImageWithFallback
          alt="Picture of a pet"
          fallback={`${publicRuntimeConfig.staticPath}/images/homepage/AccidentCover.png`}
          loading="lazy"
          webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/AccidentCover.png`}
        />
      )}
      <div className={styles.contentWrapper}>
        <div className={styles.mainContent}>
          <div className={styles.headingWrapper}>
            <Heading
              alignment="centerAlign"
              fontFamily="heading"
              size={isLargeFromViewport ? 'medium' : 'small1x'}
            >
              Rest easy with
            </Heading>
            <div className={styles.accidentCoverTitleContainer}>
              <IconShieldCheckOutlined size={isLargeFromViewport ? 'small' : 'small2x'} />
              <Heading
                alignment="centerAlign"
                fontFamily="heading"
                size={isLargeFromViewport ? 'medium' : 'small1x'}
              >
                Accident Cover
              </Heading>
            </div>
          </div>
          <div className={styles.description}>
            Included with every booking on Mad Paws.{' '}
            <span>
              <a href={MP_COVER_LINK} rel="noopener noreferrer" target="_blank">
                Learn more
              </a>
            </span>
          </div>
        </div>

        <div className={styles.accidentCovers}>
          {COVERS?.map(({ title, description }) => (
            <div className={styles.accidentCoverWrapper} key={title}>
              <div className={styles.accidentCoverTitle}>{title}</div>
              <div className={styles.accidentCover}>{description}</div>
            </div>
          ))}
        </div>
      </div>
      {isLargeFromViewport && (
        <ImageWithFallback
          alt="Picture of a pet"
          fallback={`${publicRuntimeConfig.staticPath}/images/homepage/AccidentCover.png`}
          loading="lazy"
          webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/AccidentCover.png`}
        />
      )}
    </section>
  );
};
