import { Heading, useLargeFromMediaQuery, useMediumFromMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';

import { DesktopSearchWidgetWrapper } from '~/common/components/SearchBarWidget/DesktopSearchWidgetWrapper/DesktopSearchWidgetWrapper';
import { MobileSearchWidgetWrapper } from '~/common/components/SearchBarWidget/MobileSearchWidgetWrapper/MobileSearchWidgetWrapper';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import styles from './FindPetCare.module.css';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const FIND_PET_CARE_HTML_ID = 'Explore_more_product_services';

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  searchFilters: SearchFilters;
};

export const FindPetCare = ({ searchFilters, handleFiltersSave }: Props): ReactElement | null => {
  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  const isLargeFromViewport = useLargeFromMediaQuery();
  const isMediumFromViewport = useMediumFromMediaQuery();

  if (!isHomePageComponentChangesEnabled) {
    return null;
  }

  return (
    <section className={styles.root} id={FIND_PET_CARE_HTML_ID}>
      {isLargeFromViewport && (
        <>
          <div className={styles.titleContainer}>
            <Heading
              alignment="centerAlign"
              fontFamily="heading"
              hasTightLineHeight
              level={2}
              size={'large1x'}
            >
              Find trusted pet carers near you
            </Heading>
            <Heading alignment="centerAlign" level={1}>
              Choose from 50,000+ insured local pet sitters & dog walkers
            </Heading>
          </div>
          <DesktopSearchWidgetWrapper
            handleFiltersSave={handleFiltersSave}
            searchFilters={searchFilters}
          />
        </>
      )}

      {!isLargeFromViewport && (
        <div className={styles.mobileSearchWrapper}>
          <img
            alt="group pet illustration"
            src={`${publicRuntimeConfig.staticPath}/images/ServiceLandingPages/group_pet_illustration.svg`}
          />

          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            hasTightLineHeight
            level={2}
            size={isMediumFromViewport ? 'medium' : 'small3x'}
          >
            Find your perfect sitter on Australia’s largest online pet care marketplace
          </Heading>

          <MobileSearchWidgetWrapper
            handleFiltersSave={handleFiltersSave}
            searchFilters={searchFilters}
          />
        </div>
      )}
    </section>
  );
};
