import {
  Heading,
  IconCheck,
  IconShieldPaw,
  useLargeFromMediaQuery,
  IconShieldStar,
} from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import styles from './Trust.module.css';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const TRUST_HTML_ID = 'Explore_more_product_services';

export const Trust = (): ReactElement => {
  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  const isLargeFromViewport = useLargeFromMediaQuery();

  const trustBuilders = isHomePageComponentChangesEnabled
    ? [
        'All Mad Paws sitters have their identity verified.',
        'Read reviews written by pet owners who have previously booked.',
        'Filter search results by sitters who have provided police checks.',
      ]
    : [
        'All sitters have their identity verified, and can choose to add police checks',
        'Every booking is covered by Accident Cover',
        'Access to our customer support team',
      ];

  const title = isHomePageComponentChangesEnabled
    ? 'Trustworthy & safe'
    : isLargeFromViewport
    ? 'Book with peace of mind'
    : 'Sitters you can trust and your pets will love';

  const renderShieldIcon = (): ReactElement | null => {
    if (isHomePageComponentChangesEnabled) {
      return null;
    }

    return (
      <div className={styles.iconContainer}>
        {isLargeFromViewport ? <IconShieldPaw size="medium" /> : <IconShieldStar size="small2x" />}
      </div>
    );
  };

  const webPImage = !isHomePageComponentChangesEnabled ? 'TrustBuilding.webp' : 'TrustNew.webp';
  const fallbackImage = !isHomePageComponentChangesEnabled ? 'TrustBuilding.jpg' : 'TrustNew.png';

  return (
    <section
      className={`${styles.root} ${!isHomePageComponentChangesEnabled && styles.oldRoot}`}
      id={TRUST_HTML_ID}
    >
      <div
        className={`${styles.contentWrapper} ${
          isHomePageComponentChangesEnabled && styles.whiteContentWrapper
        }`}
      >
        <div className={styles.headingWrapper}>
          {renderShieldIcon()}
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            size={isLargeFromViewport ? 'large1x' : 'small1x'}
          >
            {title}
          </Heading>
        </div>
        <div className={styles.trustBuilders}>
          {trustBuilders?.map((trustBuilder) => (
            <div className={styles.trustBuilderWrapper} key={trustBuilder}>
              <IconCheck />
              <div className={styles.trustBuilder}>{trustBuilder}</div>
            </div>
          ))}
        </div>
      </div>
      <ImageWithFallback
        alt="Picture of a trusted pet sitter"
        fallback={`${publicRuntimeConfig.staticPath}/images/ServiceLandingPages/TrustedSitters/${fallbackImage}`}
        loading="lazy"
        webpSrc={`${publicRuntimeConfig.staticPath}/images/ServiceLandingPages/TrustedSitters/${webPImage}`}
      />
    </section>
  );
};
