import { Heading, useLargeFromMediaQuery } from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { DesktopSearchWidgetWrapper } from '~/common/components/SearchBarWidget/DesktopSearchWidgetWrapper/DesktopSearchWidgetWrapper';
import { MobileSearchWidgetWrapper } from '~/common/components/SearchBarWidget/MobileSearchWidgetWrapper/MobileSearchWidgetWrapper';
import {
  HOME_PAGE_CONVERSION_TEST_EPPO_ID,
  HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES,
} from '~/components/Eppo/constants';
import { useEppoStringAssignment } from '~/components/Eppo/helpers';

import styles from './Hero.module.css';
import { ProductReview } from './components/ProductReview';
import { HERO_DETAILS } from './constants';

import type { ReactElement } from 'react';
import type { SearchFilters } from '~/common/types/search';

const { publicRuntimeConfig } = getConfig();

type Props = {
  handleFiltersSave: (searchFilters: SearchFilters) => void;
  searchFilters: SearchFilters;
};

export const Hero = ({ searchFilters, handleFiltersSave }: Props): ReactElement => {
  const isLargeFromViewport = useLargeFromMediaQuery();

  const isHomePageComponentChangesEnabled =
    useEppoStringAssignment(HOME_PAGE_CONVERSION_TEST_EPPO_ID) ===
    HOME_PAGE_CONVERSION_TEST_EPPO_ID_VALUES.ON;

  return (
    <>
      <div className={styles.root}>
        <div className={styles.titleContainer}>
          <Heading
            alignment="centerAlign"
            fontFamily="heading"
            hasTightLineHeight
            level={2}
            size={isLargeFromViewport ? 'large2x' : 'large1x'}
          >
            Press paws on pet stress
          </Heading>
          <Heading alignment="centerAlign" level={1}>
            Find trusted pet sitters & dog walkers near you
          </Heading>
        </div>
        {isLargeFromViewport && isHomePageComponentChangesEnabled && <ProductReview />}
        {!isLargeFromViewport && (
          <div className={styles.mobileSearchWrapper}>
            <MobileSearchWidgetWrapper
              handleFiltersSave={handleFiltersSave}
              searchFilters={searchFilters}
            />
            <ProductReview />
          </div>
        )}
        <DesktopSearchWidgetWrapper
          handleFiltersSave={handleFiltersSave}
          searchFilters={searchFilters}
        />
        <div className={styles.imageWrapper}>
          {isLargeFromViewport ? (
            <ImageWithFallback
              alt="Picture of dog walker walking with two dogs"
              fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.jpg`}
              height={526}
              loading="eager"
              webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_desktop.webp`}
              width={1280}
            />
          ) : (
            <ImageWithFallback
              alt="Picture of dog walker walking with two dogs"
              fallback={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.jpg`}
              height={290}
              loading="eager"
              webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/homepage_mobile.webp`}
              width="100%"
            />
          )}
        </div>
        {isLargeFromViewport && !isHomePageComponentChangesEnabled && <ProductReview />}
      </div>
      {isHomePageComponentChangesEnabled && (
        <div className={styles.details}>
          {HERO_DETAILS.map((detail) => (
            <div className={styles.detail} key={detail.title}>
              <p className={styles.detailTitle}>{detail.title}</p>
              <p className={styles.detailDescription}>{detail.description}</p>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
